<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet"
    inset
  >
    <v-sheet
      class="text-center"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          @click="$emit('close')"
          v-touch="{
            down: () => $emit('close')
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <div
        class="my-bottom-sheet-content"
      >
        <v-row>
          <v-col cols="12" class="text-left pt-5">
            <div class="title-12">Contact Details</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-form>
              <v-text-field
                v-model="firstName"
                label="Firstname"
                background-color="#F2F2F2"
                class="my-text-field"
                color="#001254"
                solo
                flat
                rounded
                height="46"
                hide-details
              ></v-text-field>

              <v-text-field
                v-model="lastName"
                label="Lastname"
                background-color="#F2F2F2"
                class="my-text-field"
                color="#001254"
                solo
                flat
                rounded
                height="46"
                hide-details
              ></v-text-field>

              <v-text-field
                v-model="email"
                label="Email"
                background-color="#F2F2F2"
                class="my-text-field"
                color="#001254"
                solo
                flat
                rounded
                height="46"
                hide-details
              ></v-text-field>

              <v-text-field
                v-model="phone"
                label="Telephone"
                background-color="#F2F2F2"
                class="my-text-field"
                color="#001254"
                solo
                flat
                rounded
                height="46"
                hide-details
              ></v-text-field>

              <v-row>
                <v-col cols="12">
                  <v-btn
                    block
                    depressed
                    class="title-12 confirm-btn"
                    @click="$emit('edit-user')"
                  >
                    Confirm
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'CheckoutContactDetails',
    props: ["visible"],
    computed: {
      ...mapGetters(["editProfile"]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      firstName: {
        get() {
          return this.$store.state.profile.editProfile.firstName;
        },
        set(value) {
          let payload = {prop: 'firstName', value: value};
          this.$store.commit('setEditProfileProp', payload);
        }
      },
      lastName: {
        get() {
          return this.$store.state.profile.editProfile.lastName;
        },
        set(value) {
          let payload = {prop: 'lastName', value: value};
          this.$store.commit('setEditProfileProp', payload);
        }
      },
      email: {
        get() {
          let result = this.editProfile.email;
          return result;
        },
        set(value) {
          let payload = {prop: 'email', value: value};
          this.$store.commit('setEditProfileProp', payload);
        }
      },
      phone: {
        get() {
          let result = this.editProfile.phoneNumber;
          return result;
        },
        set(value) {
          let payload = {prop: 'phoneNumber', value: value};
          this.$store.commit('setEditProfileProp', payload);
        }
      }
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn"
    })
  };
</script>
